import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from '@undataforum/gatsby-theme-theme-ui';
import SharingIcon from '../../src/components/sharing-icon';
import MdxLayout from '../../src/components/mdx-layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box sx={{
      color: 'primary',
      mt: -4,
      mb: -3
    }} mdxType="Box">
  <SharingIcon height={128} mdxType="SharingIcon" />
    </Box>
    <p>{`This section provides a space for members of the statistical community to share
their immediate needs, challenges, experiences and lessons learnt as they put in
place new approaches to adapt their data production and dissemination practices
throughout the statistical value chain.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Statistics COVID-19 response hotline`}</strong>{`. The United Nations Statistics
Division, in collaboration with the Global Partnership for Sustainable
Development Data and Open Data Watch, as well as statistical offices from
across the UN System, has created a dedicated email address where National
Statistical Offices and stakeholders from all sectors of society can send
inquiries and share experiences and lessons learnt related to the response of
national and global statistical systems. Please submit your inquiries to
`}<a parentName="p" {...{
            "href": "mailto:covid-19.stats@un.org"
          }}>{`covid-19.stats@un.org`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Twitter chats and webinars`}</strong>{`. Please stay tuned for upcoming virtual events
dedicated to the sharing of experiences among statistical experts from across
the world working to support national statistical systems in their response to
the COVID-19 crisis.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Electronic mailing lists for National Statistical Offices`}</strong>{`. To provide an
easy-to-use medium for the staff of National Statistical Offices to share
experiences with their peers and what they have found to be useful in meeting
the extraordinary challenges posed by the COVID-19 pandemic, the Global
Partnership for Sustainable Development Data has established an English,
French and Spanish closed email listserv. Officials from National Statistical
Offices around the world are welcome to contact `}<a parentName="p" {...{
            "href": "mailto:info@data4sdgs.org"
          }}>{`info@data4sdgs.org`}</a>{` to join
these groups.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      